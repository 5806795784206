<template>
	<div class="wrapper">
		<div class="textWrapper">
			<h1 class="bannerTitle">
				<div class="bannerDesktop">
					<span class="firstLine">Want to play the next big game?</span>
					<span class="secondLine">You've come to the right place. </span>
				</div>
			</h1>
			<h1 class="bannerTitle">
				<div class="bannerMobile">
					<span class="firstLine"
						>Want to play <br />
						the next big game?</span
					>
					<span class="secondLine"
						>You've come <br />
						to the right place.</span
					>
				</div>
			</h1>
		</div>

			<video id="myVideo" autoplay muted loop playsinline disablePictureInPicture="true" preload="auto">
				<source src="https://cdn.outfit7.com/web/transfered/wide.webm" type="video/webm" />
				<source src="https://cdn.outfit7.com/web/transfered/Wide.mp4" type="video/mp4" />
			</video>
      
	</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
@import '~/assets/design';

//set 2 banners for different widths
.bannerDesktop {
	display: none;

	@media screen and (min-width: 768px) {
		display: block;
	}
}

.bannerMobile {
	display: block;

	@media screen and (min-width: 768px) {
		display: none;
	}
}

// general styles
h1.bannerTitle {
	position: absolute;
	display: block;
	width: 100%;
	font-family: 'O7TF Display';
	font-weight: 600;

	span {
		display: block;
		font-size: 32px;
		line-height: 36px;
		opacity: 0.8;

		@media screen and (min-width: 768px) {
			font-size: 40px;
			line-height: 1;
		}

		@media screen and (min-width: 992px) {
			font-size: 48px;
		}
	}

	@media screen and (max-width: 768px) {
		top: -20px;
	}
}
.firstLine {
	position: absolute;
}

.secondLine {
	position: absolute;
	top: 110px;
	right: 0;

	@media screen and (min-width: 768px) {
		top: 80px;
		right: 0;
		margin-left: 30%;
		margin-left: unset;
		// animation: moveRight 200s infinite alternate ease;
	}
}

.wrapper {
	// relative wrapper for absolutely positioned elements on hero banner
	position: relative;
	height: 360px;
	margin-left: -2px;

	@media screen and (min-width: 768px) {
		height: 460px;
	}

	@media screen and (min-width: 992px) {
		height: 660px;
	}
	.textWrapper {
		position: absolute;
		top: 20%;
		right: 0;
		left: 0;
		z-index: 9;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 90%;
		max-width: 440px;
		margin: auto;

		@media screen and (min-width: 768px) {
			top: 30%;
			max-width: 1000px;
		}
	}

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
		content: '';
		background: linear-gradient(
			180deg,
			rgba(21, 21, 21, 0) 0%,
			rgba(21, 21, 21, 0.0086472) 6.67%,
			rgba(21, 21, 21, 0.03551) 13.33%,
			rgba(21, 21, 21, 0.0816599) 20%,
			rgba(21, 21, 21, 0.147411) 26.67%,
			rgba(21, 21, 21, 0.231775) 33.33%,
			rgba(21, 21, 21, 0.331884) 40%,
			rgba(21, 21, 21, 0.442691) 46.67%,
			rgba(21, 21, 21, 0.557309) 53.33%,
			rgba(21, 21, 21, 0.668116) 60%,
			rgba(21, 21, 21, 0.768225) 66.67%,
			rgba(21, 21, 21, 0.852589) 73.33%,
			rgba(21, 21, 21, 0.91834) 80%,
			rgba(21, 21, 21, 0.96449) 86.67%,
			rgba(21, 21, 21, 0.991353) 93.33%,
			#151515 100%
		);
		opacity: 1;
	}

	video {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>

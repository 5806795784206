<template>
	<div class="games-container">
		<a
			v-for="(card, i) in cards"
			@click="trackEvent('click', {props: {category: 'game', label: card.text}})"
			:href="card.url"
			:key="i"
			class="game-card"
			:class="card.class"
		>
			<div class="game-card_logo-area">
				<img :src="card.img" class="logo-area_logo" />
				<div class="logo-area_btn">{{ card.text }}</div>
			</div>
		</a>
	</div>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#imports';
const { $event } = useNuxtApp();

const trackEvent = $event;

// Types
interface CardInterface {
	url: string;
	class: string;
	img: string;
	text: string;
}

// Refs/reactives
const cards = ref<CardInterface[]>([
	{
		url: 'https://o7neo.co/ml-gl-web-hds',
		class: 'mythic-legends',
		img: 'https://cdn.outfit7.com/web/talking-tom-and-friends/web/ML-logo.png',
		text: 'Become a legend'
	}
]);
</script>

<style scoped lang="scss">
@import '~/assets/design';
.games-container {
	width: 100%;
	padding: 0 15px;
	color: #fff;
	background: inherit;

	.game-card {
		position: relative;
		display: flex;
		width: 100%;
		max-width: 1160px;
		height: 500px;
		margin: 0 auto;
		overflow: hidden;
		color: #fff;
		text-decoration: none !important;
		background-repeat: no-repeat;
		background-size: auto 100%;
		border: 0.1px solid rgba(255, 255, 255, 0.2);
		border-radius: 14px;

		@media screen and (max-width: 1120px) {
			height: 475px;
		}

		@media screen and (max-width: 1030px) {
			height: 450px;
		}

		@media screen and (max-width: 980px) {
			height: 425px;
		}

		@media screen and (max-width: 940px) {
			height: 400px;
		}

		@media screen and (max-width: 940px) {
			height: 400px;
		}

		@media screen and (max-width: 912px) {
			height: 950px;
			background-position: bottom;
			background-size: 100% auto;
		}

		@media screen and (max-width: 800px) {
			height: 900px;
		}

		@media screen and (max-width: 750px) {
			height: 850px;
		}

		@media screen and (max-width: 680px) {
			height: 780px;
		}

		@media screen and (max-width: 620px) {
			height: 740px;
		}

		@media screen and (max-width: 550px) {
			height: 700px;
		}

		@media screen and (max-width: 500px) {
			height: 680px;
		}

		@media screen and (max-width: 440px) {
			height: 620px;
		}

		@media screen and (max-width: 390px) {
			height: 580px;
		}

		@media screen and (max-width: 330px) {
			height: 540px;
		}

		&:not(:last-of-type) {
			margin-bottom: 40px;
		}

		.game-card_logo-area {
			position: absolute;
			top: 120px;
			right: 78px;
			width: 231px;
			display: flex;
			flex-direction: column;

			@media screen and (max-width: 1120px) {
				top: 100px;
				right: 40px;
			}

			@media screen and (max-width: 980px) {
				top: 80px;
			}

			@media screen and (max-width: 912px) {
				top: 39px;
				right: 50%;
				transform: translateX(50%);
			}

			.logo-area_logo {
				width: auto;
				max-width: max-content;
				height: auto;
				max-height: max-content;
				margin: 0 auto;
			}

			.logo-area_btn {
				display: inline-block;
				padding: 8px 20px;
				margin: 30px auto;
				font-size: 16px;
				line-height: 22px;
				background: $color-stroke-red;
				border-radius: 4px;
				font-family: 'O7TF Display';
				font-weight: 600;
			}
		}

		&.mythic-legends {
			background-color: #2c1a24;
			background-image: url('https://cdn.outfit7.com/web/mythic-legends/ML-desktop-2.png');

			@media screen and (max-width: 912px) {
				background-image: url('../assets/images/ML-mobile-2X.png');
			}
		}

	
	}
}
</style>

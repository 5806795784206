<template>
	<div>
		<!-- Single image banner insterad of carousel -->
		<home-banner></home-banner>
		<!-- Content wrapper -->
		<div class="content">
			<!-- Games section -->
			<section class="gamesContent" id="games" ref="games">
				<games-container></games-container>
			</section>

			<!-- Running into an anchor link form menu issue when flexboxing 2 elements -->
			<div id="about" ref="about"></div>
			<!-- About is super short no need for separate component -->
			<div class="detailsWrapper">
				<div class="details">
					<section>
						<div class="gradientWrapper">
							<div class="contentWrapper">
								<h2>About</h2>
								<p>
									Outfit7 Neo is a mobile game publishing house, part of Outfit7 Group.
									<br />
									<br />
									Aiming to publish new mechanics and cross-segment IPs, Outift7 Neo is on a mission to carry out Outfit7’s
									vision and continue its pioneering work, taking gaming in bold new directions and challenging
									conventional thinking along the way.

									<!-- Watch this space to discover our upcoming new games. -->
								</p>
							</div>
						</div>
					</section>

					<section>
						<contact-form />
					</section>
				</div>
			</div>
			<!-- Running into an anchor link form menu issue when flexboxing 2 elements -->
			<div id="contact" ref="contact"></div>
		</div>
	</div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import '~/assets/design';

.content {
	font-family: 'O7TF Text';
	background-color: #151515;

	.gamesContent {
		position: relative;
		z-index: 9;
		margin-top: -50px;
		@media screen and (min-width: 992px) {
			margin-top: -180px;
		}
	}

	.subtitlesWrapper {
		position: relative;
		padding: 60px 10% 30px;
		margin: 0 auto;

		@media screen and (min-width: 992px) {
			width: 90%;
			max-width: 1080px;
			padding: 60px 2% 30px;
		}

		@media screen and (min-width: 1200px) {
			max-width: 1300px;
			padding: 72px 0 46px;
		}
		h2 {
			font-family: 'O7TF Display';
			font-weight: 600;
			max-width: 300px;
			margin: 0 0 40px;
			font-size: 2rem;
			line-height: 40px;
			color: #fff;
			text-align: left;
			@media screen and (min-width: 500px) {
				max-width: 500px;
			}

			@media screen and (min-width: 768px) {
				max-width: unset;
				margin: 0 0 60px;
				font-size: 2.5rem;
				line-height: 1;
			}

			@media screen and (min-width: 1300px) {
				font-size: 2.8rem;
			}
		}
		.shaded {
			align-self: flex-end;
			margin-bottom: 0;
			color: #707070;
			text-align: right;
		}
	}
	.detailsWrapper {
		padding: 0 15px;
		background: #151515;
		opacity: 1;
	}

	.details {
		position: relative;
		width: 100%;
		max-width: 560px;
		margin: 0 auto;
		margin-top: 50px;

		@media screen and (max-width: 912px) {
			margin-top: 60px;
		}

		> section {
			flex-basis: 0;
			flex-grow: 1;
		}

		.gradientWrapper {
			opacity: 1;

			@media screen and (min-width: 992px) {
				background: unset;
			}
			.contentWrapper {
				position: relative;
				display: block;

				h2 {
					font-family: 'O7TF Display';
					font-weight: 600;
					margin: 100px 0 26px;
					font-size: 36px;
					line-height: 49px;
					color: #fff;
					text-align: center;
				}
				p {
					margin-bottom: 0;
					font-size: 18px;
					line-height: 25px;
					color: #ccc;
					text-align: left;
				}
			}
		}
	}

	.border {
		height: 5px;
		background: transparent linear-gradient(270deg, #82003c 0%, $color-stroke-red 100%) 0% 0% no-repeat padding-box;
	}
}
</style>
